@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(./assets/Square.svg) !important;
  background-size: 2% !important;
  color: black !important;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 20px;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.btn {
  background-color: black;
  padding: 15px 35px;
  color: white;
  border: none;
  border-radius: 5px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.btn:hover {
  transform: translateY(-5px);
}

.alert {
  background-color: red;
}

/* NAV */

.grid-logo {
  cursor: pointer;
  transition: transform 150ms ease-in-out;
}

.grid-logo:hover {
  transform: scale(1.25);
}

.nav-link {
  color: #000;
  transition: 100ms ease-in-out;
}

.nav-link:hover {
  color: aqua;
}

/* HERO */

.hero-div {
  background-size: cover;
  background-position: center;
  height: calc(100vh - 70px);
  display: flex !important  ;
  align-items: center;
  justify-content: center;
}

.hero-1 {
  background-image: url(./assets/hero/Hero.png);
}

.hero-2 {
  background-image: url(./assets/hero/hero2.png);
}

.hero-3 {
  background-image: url(./assets/hero/hero3.png);
}

.slick-dots {
  bottom: 25px !important;
}

.slick-dots li button:before {
  color: white !important;
  font-size: 10px !important;
}

/* CARDS */

.card {
  /* max-width: 294px; */
  min-height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: black;
  padding-bottom: 60px;
  background-color: #F6F6F6;
}

.card h2 {
  font-weight: bold;
  font-size: 34px;
  opacity: 1;
}

.card h3 {
  font-weight: bold;
  font-size: 30px;
  text-transform: capitalize;
}

.card h4 {
  font-weight: bold;
  opacity: 0.75;
  text-transform: capitalize;
}

.card h6 {
  font-size: 12px;
  opacity: 0.6;
}

.card p {
  font-size: 14px;
  opacity: 0.6;
}

.card a {
  font-size: 13px;
  text-decoration: underline;
  opacity: 0.6;
  color: black;
}

.card a:hover {
  opacity: 1;
}

.card hr {
  background-color: black;
  height: 1px;
  border: none;
  opacity: 0.3;
}

.card li {
  font-size: 14px;
}

.card-img {
  width: 80px;
  height: 80px;
  margin: 15px;
  border-radius: 40px;
}

.card-promo {
  background-color: green;
  color: white;
}

.card .btn {
  background-color: black;
  color: white;
}

.card-primario {
  background-color: #FAD749;
}

/* AREAS */

.area {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  transition: transform 300ms ease-in-out;
}

.area:hover {
  transform: scale(1.1);
}

.area-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.4);
  opacity: 0;
  transition: 300ms;
}

.area-content:hover {
  opacity: 1;
}

/* MEMBERSHIPS */

.memberships {
  background-color: transparent;
  color: black;
  padding: 60px 0;
}
/* FOOTER */

.footer-main-container {
  width: 100%;
  color: white;
}

.footer-vector {
  width: 100%;
}

.footer-sub-container {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin-top: -4px;
  background-color: #1f4447;
}

.logotype {
  width: 100px;
}

.footer-items-container {
  padding: 10px;
}

.hover {
  padding: 15px;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.hover:hover {
  transform: translateY(-5px);
}

/* CLASES */

.clases {
  background-color: #FAD749;
  padding: 200px 0;
  clip-path: polygon(0 25%, 100% 0, 100% 75%, 0 100%);
}

.clases-card {
  transition: transform 0.3s ease-in-out;
}

.clases-card:hover {
  transform: translateY(-10px);
}

.clases-img {
  border-radius: 5px;
}